var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Card', [_c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" Create vendor")]), _c('VendorEditor', {
    attrs: {
      "create": true
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }