<template>
  <Card>
    <span slot="title"> Create vendor</span>
    <VendorEditor :create="true" />
  </Card>
</template>
<script>
import VendorEditor from '@/views/Vendors/FicheVendor/VendorEditor'
export default {
  name: 'AdminCreateVendor',
  components: { VendorEditor },
  data: () => {
    return {}
  }
}
</script>
